import Swiper, { Navigation } from 'swiper';

const heroSliders = document.querySelectorAll('.js-sponsor-slider-block');


heroSliders.forEach((sliderElement) => {
    new Swiper(sliderElement, {
        slidesPerView: 4,
        spaceBetween: 16,
        // modules: [Navigation],
        // navigation: {
        //     prevEl: '.sponsor__arrow-left',
        //     nextEl: '.sponsor__arrow-right',
        // },
        breakpoints: {
            1200: {
                slidesPerView: 4,
            },
            776: {
                slidesPerView: 2,
                spaceBetween: 16,
            },
            400: {
                slidesPerView: 2,
                spaceBetween: 16,
            },
            360: {
                slidesPerView: 2,
                spaceBetween: 16,
            },
            300: {
                slidesPerView: 2,
                spaceBetween: 16,
            },
        },
    });
});
